import {defineStore} from 'pinia'
import type {LocalParticipant} from "~/models/localParticipant";
import type {LocalAuthorizedPickup} from "~/models/localAuthorizedPickup";
import {ParentType} from "~/utils/enums/ParentType";
import jwtDecode from "jwt-decode";
import type {ParticipantPronoun} from "~/models/participantPronoun";
import {
    useGlobalEvent,
    useLoggedIdUserDataPreparationEvent,
    useNotificationEvent,
    useSignupDataSubmitEvent
} from "~/composables/useEventBus";
import type {NotificationDetails} from "~/models/notificationDetails";
import {NotificationType} from "~/utils/enums/NotificationType";
import {studentStore} from "~/store/studentStore";
import type {StudentRb} from "~/models/request/studentRb";
import {sessionStore} from "~/store/sessionStore";
import type {AddStudentIntoSessionRb} from "~/models/request/addStudentIntoSessionRb";
import {clubStore} from "~/store/clubStore";
import type {AddStudentIntoClubRb} from "~/models/request/addStudentIntoClubRb";
import {parentStore} from "~/store/parentStore";
import type {ParentRb} from "~/models/request/parentRb";
import type {AddUpdateStudentParentRelationRb} from "~/models/request/addUpdateStudentParentRelationRb";
import {userStore} from "~/store/userStore";
import type {EmailRb} from "~/models/request/emailRb";
import {CommonConstants} from "~/utils/enums/CommonConstants";
import type {ParentDetails, ParentUnderStudentDetails, StudentDetails} from "~/models/parentDetails";
import type {GoogleSignInResponse} from "~/models/googleSignInResponse";
import {AppRoutes} from "~/utils/other/AppRoutes";
import moment from "moment";
import {Mask} from "maska";
import {UserFlowType} from "~/utils/enums/UserFlowType";
import {UserRegistrationType} from "~/utils/enums/UserRegistrationType";
import {authenticationStore} from "~/store/authenticationStore";
import type {User} from "~/models/user";
import {companyStore} from "~/store/companyStore";
import {gradeStore} from "~/store/gradeStore";
import {StudentStatus} from "~/utils/enums/StudentStatus";
import {UserLoginAccessDecision} from "~/utils/enums/UserLoginAccessDecision";
import {feeStore} from "~/store/feeStore";

export const signupStore = defineStore({
    id: 'signup-store',
    persist: true,
    state: () => {
        return {
            studentClubAssignments: <any>[],
            participants: <LocalParticipant[]>[],
            authorizedPickups: <LocalAuthorizedPickup[]>[],
            participantPronouns: <ParticipantPronoun[]>[],
            authPickupRelations: <string[]>[],
            addedStudentsForPayment: <any[]>[], //Newly added or updated students who are new to the club
            signUpToken: '',
            signUpCompanyId: -1,
            signUpClubId: -1,
            signUpSessionId: -1,
            signUpIsClub: false,
            hasParentModule: false,
            hasPaymentModule: false,
            hasZoneModule: false,
            hasAttendanceModule: false,
            hasAuthPickupModule: false,
            userFlowType: UserFlowType.SIGN_UP_LINK_USER,
            userRegistrationType: UserRegistrationType.NEW,
        }
    },
    actions: {
        clearSignUpStore: function () {
          this.clearAddedStudentsForPayment();
          this.clearParticipants();
          this.clearAuthorizedPickups();
          this.clearStudentClubAssignments();
          this.signUpToken = '';
          this.signUpCompanyId = -1;
          this.signUpClubId = -1;
          this.signUpSessionId = -1;
          this.signUpIsClub = false;
          this.hasParentModule = false;
          this.hasPaymentModule = false;
          this.hasZoneModule= false;
          this.hasAttendanceModule= false;
        },
        setUserRegistrationType: function (userRegistrationType: UserRegistrationType) {
          this.userRegistrationType = userRegistrationType;
        },
        loadParticipantPronoun() {
            this.participantPronouns = [<ParticipantPronoun>{
                id: -1,
                pronoun: 'Select pronoun*',
                value: ''
            }, <ParticipantPronoun>{
                id: 1,
                pronoun: 'He/Him',
                value: 'Male'
            }, <ParticipantPronoun>{
                id: 2,
                pronoun: 'She/Her',
                value: 'Female'
            }, <ParticipantPronoun>{
                id: 3,
                pronoun: 'They/Them',
                value: 'They'
            }];
        },
        loadAuthPickupRelations() {
            this.authPickupRelations = ['Parent', 'Other']
        },
        addAuthPickupRelations(relations: string[]) {
            function onlyUnique(value: any, index: any, array: any) {
                return array.indexOf(value) === index;
            }

            const relationsWithDuplicates = [...this.authPickupRelations, ...relations];
            this.authPickupRelations = relationsWithDuplicates.filter(onlyUnique)
        },
        setSignUpValues(token: string, clubId: string, sessionId: string, isClub: string) {
            try{
                this.populatePermissionsFromToken(token);
                this.signUpClubId = Number(clubId);
                this.signUpSessionId = Number(sessionId);
                this.signUpIsClub = Boolean(isClub);
            }catch (e) {
                console.log(e)
            }
        },
        setSignUpValuesFromUserDetails(user: User) {
            const useUserStore = userStore()
            useUserStore.setLoggedInUser(user);
            const token = user.SessionToken;
            this.populatePermissionsFromToken(token);
            this.signUpCompanyId = user.CompanyId;
        },
        populatePermissionsFromToken(token: string){
            try{
                this.signUpToken = token;
                this.signUpCompanyId = this.getCompanyIdFromToken(token);
                const decodedToken:any = jwtDecode(token);
                if(decodedToken.modules.find((module:any)=>module.Name=="Parent")){
                    this.hasParentModule = true;
                }
                if(decodedToken.modules.find((module:any)=>module.Name=="Payment")){
                    this.hasPaymentModule = true;
                }
                if(decodedToken.modules.find((module:any)=>module.Name=="Attendance")){
                    this.hasAttendanceModule = true;
                }
                if(decodedToken.modules.find((module:any)=>module.Name=="Zone")){
                    this.hasZoneModule = true;
                }
                if(decodedToken.modules.find((module:any)=>module.Name=="Authorized Pickups")){
                    this.hasAuthPickupModule = true;
                }
            }catch (e){
                console.log(e);
            }
        },
        getCompanyIdFromToken(token: string) {
            const decodedToken:any = jwtDecode(token) as Map<string, any>;
            const companies = decodedToken?.companies ?? [];
            if (companies.length > 0) {
                return companies[0].company_id;
            }
        },
        validateEmail(email: string) {
            return email.toLowerCase()
                .match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                );
        },
        clearAuthorizedPickups() {
            this.authorizedPickups = <LocalAuthorizedPickup[]>[];
        },
        clearParticipants() {
            this.participants = <LocalParticipant[]>[];
        },
        addNewLocalAuthorizedPickup(authorizedPickup: LocalAuthorizedPickup) {
            this.authorizedPickups.push(authorizedPickup);
        },
        addNewParticipant(participant: LocalParticipant) {
            this.participants.push(participant);
        },
        addNewAuthorizedPickup(authPickup: LocalAuthorizedPickup) {
            this.authorizedPickups.push(authPickup);
        },
        clearAddedStudentsForPayment: function (){
            this.addedStudentsForPayment = [];
        },
        addIntoAddedStudentForPayment: function (addedStudent:any){
            this.addedStudentsForPayment.push(addedStudent);
        },
        getIsStudentAssignedToThisClub: function (studentId:any) {
            for(const studentClubAssignment of this.studentClubAssignments){
                if(studentClubAssignment.studentId == studentId){
                    return studentClubAssignment.isAssigned == true;
                }
            }
            return false;
        },
        // Get participants who are going to be charged on payments page from a given array
        // Participants who are checked [selectedForClubAssignment] but not already assigned to the club
        getParticipantsGoingToBeChargedOnPaymentPage: function (participants: LocalParticipant[]) {
            const tmpParticipants: LocalParticipant[] = [];
            for(const participant of participants){
                if(participant.selectedForClubAssignment && !this.getIsStudentAssignedToThisClub(participant.id)){
                    tmpParticipants.push(participant)
                }
            }
            return tmpParticipants;
        },
        clearStudentClubAssignments: function () {
            this.studentClubAssignments = [];
        },
        removeAuthorizedPickup(id: any, localId: any){
            if (id) {
                this.authorizedPickups = this.authorizedPickups.filter((item) => item.id != id);
                const useParentStore =  parentStore();
                const studentIdsSavedOnServer:number[] = [];
                for(const participant of this.participants){
                    if((participant.id??-1)>0){
                        studentIdsSavedOnServer.push(participant.id!);
                    }
                }
                useParentStore.deleteParent(this.signUpCompanyId, studentIdsSavedOnServer, id).then();
            } else {
                this.authorizedPickups = this.authorizedPickups.filter((item) => item.localId != localId);
            }
        },
        removeParticipant(id: any, localId: any) {
            if (id) {
                this.participants = this.participants.filter((item) => item.id != id);
            } else {
                this.participants = this.participants.filter((item) => item.localId != localId);
            }
        },
        getPrimaryParent() {
            return this.authorizedPickups.find((item) => item.type == ParentType.PRIMARY);
        },

        // Use this function if user has parent module but doesn't have payment module
        // Assign student into session and club
        async prepareAndSaveDataForPaymentModuleDisabled(companyId: number, sessionId: number, clubId: number, participants: LocalParticipant[], authPickups: LocalAuthorizedPickup[]) {
            useGlobalEvent(useSignupDataSubmitEvent(), <any>{
                loading: true,
            })

            const useUserStore = userStore()
            const useStudentStore = studentStore()
            const useSessionStore = sessionStore()
            const useClubStore = clubStore()
            const useParentStore = parentStore()

            const primaryParent = authPickups.find((item) => item.type == ParentType.PRIMARY);
            const participantsSelectedForThisClub = participants.filter((participant)=>participant.selectedForClubAssignment);

            //No fields can be null or empty
            if (!companyId || !sessionId || !clubId || participantsSelectedForThisClub.length < 1 || authPickups.length < 2) {
                useGlobalEvent(useNotificationEvent(), <NotificationDetails>{
                    type: NotificationType.WARNING,
                    title: 'Data lacking',
                    message: 'Data set is not ready to save',
                });
                return;
            }
            //Check participant data set
            for (const singleParticipant of participantsSelectedForThisClub) {
                const firstNameValid = singleParticipant.firstName?.trim().length > 0;
                const lastNameValid = singleParticipant.lastName?.trim().length > 0;
                const pronounValid = singleParticipant.gender?.length > 0;
                const gradeValid = singleParticipant.gradeId > 0;
                if (!firstNameValid || !lastNameValid || !pronounValid || !gradeValid) {
                    useGlobalEvent(useNotificationEvent(), <NotificationDetails>{
                        type: NotificationType.WARNING,
                        title: 'Invalid data',
                        message: 'Participants data set is not ready to save',
                    });
                    return;
                }
            }
            //Check auth pickup data set
            for (const singleAuthPickup of authPickups) {
                const firstNameValid = singleAuthPickup.firstName?.trim().length > 0;
                const lastNameValid = singleAuthPickup.lastName?.trim().length > 0;
                const emailValid = (this.validateEmail(singleAuthPickup.email ?? '')?.length ?? 0) > 0;
                const phoneValid = (singleAuthPickup.phone?.trim()??'').length == 10;
                const phoneValidFormGeneral = (singleAuthPickup.phone?.trim()??'').length==0 || (singleAuthPickup.phone?.trim()??'').length==10;
                const emailValidForGeneral = (singleAuthPickup.email??'').trim().length==0 || (this.validateEmail(singleAuthPickup.email??'')?.length??0)>0;
                const relationValid = singleAuthPickup.relation?.trim().length > 0;
                const preferencesValid = singleAuthPickup.preferences?.length > 0;
                if(singleAuthPickup.type==ParentType.GENERAL){
                    if(!firstNameValid || !lastNameValid || !relationValid || !phoneValidFormGeneral || !emailValidForGeneral){
                        useGlobalEvent(useNotificationEvent(), <NotificationDetails>{
                            type: NotificationType.WARNING,
                            title: 'Invalid data',
                            message: 'Auth pickup data set is not ready to save',
                        });
                        return;
                    }
                }else{
                    if (!firstNameValid || !lastNameValid || !emailValid || !phoneValid || !relationValid || !preferencesValid) {
                        useGlobalEvent(useNotificationEvent(), <NotificationDetails>{
                            type: NotificationType.WARNING,
                            title: 'Invalid data',
                            message: 'Auth pickup data set is not ready to save',
                        });
                        return;
                    }
                }
            }

            try {
                const addedStudentsId = [];

                for (const singleParticipant of participantsSelectedForThisClub) {
                    const addedStudent = await useStudentStore.addStudent(<StudentRb>{
                        FirstName: singleParticipant.firstName,
                        Id: singleParticipant.id,
                        LastName: singleParticipant.lastName,
                        BanglaName: null,
                        Email: singleParticipant.email,
                        Phone: singleParticipant.phone,
                        Gender: singleParticipant.gender,
                        IsKinderGartenStudent: 0,
                        ProfilePicture: null,
                        ProfilePictureThumb: null,
                        StudentId: 0,
                        Allergies: singleParticipant.allergies,
                        Grade: singleParticipant.gradeId,
                        CompanyId: companyId,
                        hasLogin: false
                    });
                    await useSessionStore.addStudentIntoSession(<AddStudentIntoSessionRb>{
                        studentId: addedStudent.id,
                        sessionIds: [sessionId],
                        Status: 1,
                    });
                    await useClubStore.addStudentIntoClub(<AddStudentIntoClubRb>{
                        studentId: addedStudent.id,
                        clubIds: [clubId],
                        companyId: companyId
                    })
                    addedStudentsId.push(addedStudent.id);
                }

                for (const singleAuthPickup of authPickups) {
                    //Add country code 1 before adding phone
                    const addedParent = await useParentStore.addParent(<ParentRb>{
                        Email: this.parentEmailDecider(singleAuthPickup.email??''),
                        FirstName: singleAuthPickup.firstName,
                        LastName: singleAuthPickup.lastName,
                        CellPhone: (singleAuthPickup.phone?.trim()??'').length>0?'1'+singleAuthPickup.phone:singleAuthPickup.phone,
                        Notes: null,
                        ParentType: singleAuthPickup.type,
                        IsRegistered: 0,
                        CompanyId: companyId,
                        isSenior: false,
                        Relationship: singleAuthPickup.relation,
                        ProfilePicture: singleAuthPickup.picture,
                        id: singleAuthPickup.id,
                        Preferences: singleAuthPickup.preferences,
                    }, singleAuthPickup.googleSignUpUser);
                    for (const studentId of addedStudentsId) {
                        await useParentStore.addUpdateStudentParentMapping(<AddUpdateStudentParentRelationRb>{
                            ParentId: addedParent.id,
                            StudentId: studentId,
                            ParentType: singleAuthPickup.type,
                            Relationship: singleAuthPickup.relation,
                            Notes: '',
                            Status: 1
                        });
                    }
                }

                await useUserStore.sendEmail(<EmailRb>{
                    Email: CommonConstants.SUPPORT_EMAIL,
                    Subject: primaryParent?.firstName ?? '' + " " + primaryParent?.lastName ?? '' + " signed up for Stoddert EDP",
                    text: '',
                    Body: `Hello!<br \\>
                  <br \\>
                  This is to notify you that ${primaryParent?.firstName} ${primaryParent?.lastName} has successfully signed up for Stoddert EDP. Their details can be viewed on <a href="${CommonConstants.STUDENTS_PAGE}">Aphelia Admin portal</a> now.<br \\>
                  <br \\>
                  Best, <br \\>
                  <a href="${CommonConstants.ADMIN_PORTAL}">Aphelia Support</a>`,
                    token: "Bearer " + this.signUpToken
                });
            } catch (e) {
                useGlobalEvent(useNotificationEvent(), <NotificationDetails>{
                    type: NotificationType.ERROR,
                    title: 'Something wrong',
                    message: 'Could not complete signup request',
                });
            }

            useGlobalEvent(useSignupDataSubmitEvent(), <any>{
                loading: false,
            })
            return true;
        },
        ///Use this function if user has both parent and payment module
        // Does not assign student into session and club. Assignment will be done after payment
        async prepareAndSaveDataForPaymentModuleEnabled(companyId: number, sessionId: number, clubId: number, participants: LocalParticipant[], authPickups: LocalAuthorizedPickup[]) {
            useGlobalEvent(useSignupDataSubmitEvent(), <any>{
                loading: true,
            })

            const useUserStore = userStore()
            const useStudentStore = studentStore()
            const useParentStore = parentStore()

            const primaryParent = authPickups.find((item) => item.type == ParentType.PRIMARY);
            const participantsSelectedForThisClub = participants.filter((participant)=>participant.selectedForClubAssignment);

            //No fields can be null or empty
            if (!companyId || !sessionId || !clubId || participantsSelectedForThisClub.length < 1 || authPickups.length < 1) {
                useGlobalEvent(useNotificationEvent(), <NotificationDetails>{
                    type: NotificationType.WARNING,
                    title: 'Data lacking',
                    message: 'Required data set is not ready to save',
                });
                return;
            }
            //Check participant data set
            for (const singleParticipant of participantsSelectedForThisClub) {
                const firstNameValid = singleParticipant.firstName?.trim().length > 0;
                const lastNameValid = singleParticipant.lastName?.trim().length > 0;
                const pronounValid = singleParticipant.gender?.length > 0;
                const gradeValid = singleParticipant.gradeId != null || singleParticipant.gradeId != undefined;
                if (!firstNameValid || !lastNameValid || !pronounValid || !gradeValid) {
                    useGlobalEvent(useNotificationEvent(), <NotificationDetails>{
                        type: NotificationType.WARNING,
                        title: 'Invalid data',
                        message: 'Participants data set is not ready to save',
                    });
                    return;
                }
            }
            //Check auth pickup data set
            for (const singleAuthPickup of authPickups) {
                const firstNameValid = singleAuthPickup.firstName?.trim().length > 0;
                const lastNameValid = singleAuthPickup.lastName?.trim().length > 0;
                const emailValid = (this.validateEmail(singleAuthPickup.email ?? '')?.length ?? 0) > 0;
                const phoneValid = (singleAuthPickup.phone?.trim()??'').length == 10;
                const phoneValidFormGeneral = (singleAuthPickup.phone?.trim()??'').length==0 || (singleAuthPickup.phone?.trim()??'').length==10;
                const emailValidForGeneral = (singleAuthPickup.email??'').trim().length==0 || (this.validateEmail(singleAuthPickup.email??'')?.length??0)>0;
                const relationValid = singleAuthPickup.relation?.trim().length > 0;
                const preferencesValid = singleAuthPickup.preferences?.length > 0;
                if(singleAuthPickup.type==ParentType.GENERAL){
                    if(!firstNameValid || !lastNameValid || !relationValid || !phoneValidFormGeneral || !emailValidForGeneral){
                        useGlobalEvent(useNotificationEvent(), <NotificationDetails>{
                            type: NotificationType.WARNING,
                            title: 'Invalid data',
                            message: 'Auth pickup data set is not ready to save',
                        });
                        return;
                    }
                }else{
                    if (!firstNameValid || !lastNameValid || !emailValid || !phoneValid || !relationValid || !preferencesValid) {
                        useGlobalEvent(useNotificationEvent(), <NotificationDetails>{
                            type: NotificationType.WARNING,
                            title: 'Invalid data',
                            message: 'Auth pickup data set is not ready to save',
                        });
                        return;
                    }
                }
            }

            try {
                this.clearAddedStudentsForPayment();
                const addedStudentsId = [];

                for (const singleParticipant of participantsSelectedForThisClub) {
                    const addedStudent = await useStudentStore.addStudent(<StudentRb>{
                        FirstName: singleParticipant.firstName,
                        Id: singleParticipant.id,
                        LastName: singleParticipant.lastName,
                        BanglaName: null,
                        Email: singleParticipant.email,
                        Phone: singleParticipant.phone,
                        Gender: singleParticipant.gender,
                        IsKinderGartenStudent: 0,
                        ProfilePicture: null,
                        ProfilePictureThumb: null,
                        StudentId: 0,
                        Allergies: singleParticipant.allergies,
                        Grade: singleParticipant.gradeId,
                        CompanyId: companyId,
                        hasLogin: false
                    });


                    // If already assigned to this club do not add into AddedStudentForPayment | Do not change them for payment
                    const isAlreadyRegisteredToThisClub = this.getIsStudentAssignedToThisClub(addedStudent.id);
                    if(!isAlreadyRegisteredToThisClub){
                        this.addIntoAddedStudentForPayment(addedStudent);
                    }

                    addedStudentsId.push(addedStudent.id);
                }

                for (const singleAuthPickup of authPickups) {
                    //Add country code 1 before adding phone
                    const addedParent = await useParentStore.addParent(<ParentRb>{
                        Email: this.parentEmailDecider(singleAuthPickup.email??''),
                        FirstName: singleAuthPickup.firstName,
                        LastName: singleAuthPickup.lastName,
                        CellPhone: (singleAuthPickup.phone?.trim()??'').length>0?'1'+singleAuthPickup.phone:singleAuthPickup.phone,
                        Notes: null,
                        ParentType: singleAuthPickup.type,
                        IsRegistered: 0,
                        CompanyId: companyId,
                        isSenior: false,
                        Relationship: singleAuthPickup.relation,
                        ProfilePicture: singleAuthPickup.picture,
                        id: singleAuthPickup.id,
                        Preferences: singleAuthPickup.preferences,
                    }, singleAuthPickup.googleSignUpUser);
                    for (const studentId of addedStudentsId) {
                        await useParentStore.addUpdateStudentParentMapping(<AddUpdateStudentParentRelationRb>{
                            ParentId: addedParent.id,
                            StudentId: studentId,
                            ParentType: singleAuthPickup.type,
                            Relationship: singleAuthPickup.relation,
                            Notes: '',
                            Status: 1
                        });
                    }
                }

                //TODO: Need to fix company name
                await useUserStore.sendEmail(<EmailRb>{
                    Email: CommonConstants.SUPPORT_EMAIL,
                    Subject: primaryParent?.firstName ?? '' + " " + primaryParent?.lastName ?? '' + " signed up for Stoddert EDP",
                    text: '',
                    Body: `Hello!<br \\>
                  <br \\>
                  This is to notify you that ${primaryParent?.firstName} ${primaryParent?.lastName} has successfully signed up for Stoddert EDP. Their details can be viewed on <a href="${CommonConstants.STUDENTS_PAGE}">Aphelia Admin portal</a> now.<br \\>
                  <br \\>
                  Best, <br \\>
                  <a href="${CommonConstants.ADMIN_PORTAL}">Aphelia Support</a>`,
                    token: "Bearer " + this.signUpToken
                });
            } catch (e) {
                useGlobalEvent(useNotificationEvent(), <NotificationDetails>{
                    type: NotificationType.ERROR,
                    title: 'Something wrong',
                    message: 'Could not complete signup request',
                });
            }

            useGlobalEvent(useSignupDataSubmitEvent(), <any>{
                loading: false,
            })
            return true;
        },

        prepareExistingDataFormLoggedInUsers: async function (parentDetails: ParentDetails, googleLoginUser?: boolean) {
            useGlobalEvent(useLoggedIdUserDataPreparationEvent(), <any>{
                loading: true,
            })
            const useStudentStore = studentStore()
            const existingParentsMap = new Map<string, ParentUnderStudentDetails>()
            const existingStudentsMap = new Map<number, StudentDetails>()
            const tmpParticipants = <LocalParticipant[]>[];
            let tmpAuthPickups = <LocalAuthorizedPickup[]>[];
            const parentRelations = <string[]>[];
            const existingStudentIds:any[] = [];

            //Filter out student of other companies and non-actives
            const currentCompanyActiveStudentParents = (parentDetails.StudentParents ?? []).filter((studentParent) => {
                const tmpStudentDetails = studentParent?.Student;
                if(tmpStudentDetails?.CompanyId && tmpStudentDetails.Status){
                    return tmpStudentDetails?.CompanyId==this.signUpCompanyId && tmpStudentDetails.Status == StudentStatus.ACTIVE;
                }else{
                //TODO: For now, we are allowing all students without company id. But Company id should come with the API response and this if-else condition should be removed
                    return true;
                }
            });

            console.log(currentCompanyActiveStudentParents);

            //Get unique students and parents
            for (const parentUnderParentDetails of currentCompanyActiveStudentParents) {
                const tmpStudentDetails = parentUnderParentDetails?.Student;
                if (tmpStudentDetails) {
                    existingStudentsMap.set(tmpStudentDetails.id, tmpStudentDetails);
                    for (const parentUnderStudent of tmpStudentDetails?.StudentParents ?? []) {
                        const parentMini = parentUnderStudent?.Parent
                        if (parentMini && parentUnderStudent.Status == 1) {
                            existingParentsMap.set(parentMini.Email, parentUnderStudent);
                            parentRelations.push(parentUnderStudent.Relationship);
                        }
                    }
                }
            }

            //Load parent relations into local storage
            this.addAuthPickupRelations(parentRelations);

            console.log(existingStudentsMap)

            // Get club status by student ids
            for(const existingStudentId of existingStudentsMap.keys()){
                existingStudentIds.push(existingStudentId);
            }
            const studentClubAssignments:any[] = await useStudentStore.getClubStatusByStudentIds({
                ClubId: this.signUpClubId,
                StudentIds: existingStudentIds,
            });
            this.clearStudentClubAssignments()
            for(const studentClubAssignment of studentClubAssignments){
                this.studentClubAssignments.push({
                    studentId: studentClubAssignment.StudentId,
                    isAssigned:  studentClubAssignment.IsAssigned,
                });
            }

            //Load students into local storage
            for (const singleStudent of existingStudentsMap.values()) {

                tmpParticipants.push(<LocalParticipant>{
                    id: singleStudent.id,
                    localId: undefined,
                    firstName: singleStudent.FirstName,
                    lastName: singleStudent.LastName,
                    banglaName: undefined,
                    email: undefined,
                    phone: undefined,
                    hasLogin: false,
                    gender: singleStudent.Gender,
                    gradeId: singleStudent.Grade,
                    sessionId: this.signUpSessionId,
                    allergies: singleStudent.Allergies,
                    selectedForClubAssignment: this.getIsStudentAssignedToThisClub(singleStudent.id), //Make selected if student already assigned to the club. No selected if student not assigned
                });
            }

            //Load parents into local storage as authorized pickups
            for (const singleParent of existingParentsMap.values()) {
                let tmpPhone = '';
                if((singleParent.Parent?.CellPhone??'').length>10){
                    tmpPhone = singleParent.Parent.CellPhone.substr(1,11);
                }else{
                    tmpPhone = singleParent.Parent.CellPhone;
                }
                tmpAuthPickups.push(<LocalAuthorizedPickup>{
                    id: singleParent.Parent?.id,
                    localId: undefined,
                    firstName: singleParent.Parent.FirstName,
                    lastName: singleParent.Parent.LastName,
                    relation: singleParent.Relationship,
                    email: singleParent.Parent.Email,
                    phone: tmpPhone,
                    preferences: singleParent.Parent.Preferences,
                    type: singleParent.ParentType,
                    googleSignUpUser: googleLoginUser??false,
                });
            }


            this.participants = tmpParticipants;
            //Sort auth pickups by type so Primary parents get the initial indexes
            tmpAuthPickups = tmpAuthPickups.sort((a, b) => a.type > b.type ? -1 : 1);
            this.authorizedPickups = tmpAuthPickups;

            useGlobalEvent(useLoggedIdUserDataPreparationEvent(), <any>{
                loading: false,
            })
        },
        prepareRegistrationFlowForExploreRegistration: async function (token:string, email:string, clubId:string){
            //User will come here from parent dashboard->Explore->Register button click
            //Only parent module enabled use case
            const useUserStore = userStore();
            const useSessionStore = sessionStore();
            //Hit check parent email exist
            const userDetails = await useUserStore.checkForExistingParentEmail(email);
            if(userDetails){
                //Get active session of this company
                const activeSession = useSessionStore.getActiveSession();
                //Prepare and save sign up store data, company, token, club, students, auth pickups
                this.loadParticipantPronoun();
                this.loadAuthPickupRelations();
                this.setSignUpValues(token, clubId, activeSession?.id+"", "true");
                await this.prepareExistingDataFormLoggedInUsers(userDetails);
                //If no participant found, create a demo participant
                if(this.participants.length<1){
                    //We need to create an empty student
                    this.addNewParticipant(<LocalParticipant>{
                        localId: moment().valueOf(),
                        selectedForClubAssignment: true,
                    });
                }
                //Minimum 2 participant is required. Create 2 if have 0, create 1 if have 1
                if(this.authorizedPickups.length<2){
                    if(this.authorizedPickups.length==0){
                        let phone = userDetails.CellPhone??"";
                        if(phone.length>10){
                            phone = phone.substring(1);
                        }
                        this.addNewLocalAuthorizedPickup(<LocalAuthorizedPickup>{
                            localId: moment().valueOf(),
                            id: userDetails.id,
                            firstName: userDetails.FirstName,
                            lastName: userDetails.LastName,
                            email: userDetails.Email,
                            phone: phone,
                            picture: userDetails.ProfilePicture,
                            type: ParentType.PRIMARY,
                            googleSignUpUser: false,
                        })
                    }
                    this.addNewLocalAuthorizedPickup(<LocalAuthorizedPickup>{
                        localId: moment().valueOf()+2,
                        type: ParentType.GENERAL_PRIMARY
                    })
                }
                //Go to Add student page
            }else {
                useGlobalEvent(useNotificationEvent(), <NotificationDetails>{
                    type: NotificationType.WARNING,
                    title: 'User not found',
                    message: 'No user found as parent by your email address. Please contact support.',
                });
            }
        },

        ///For Feed guest continue with gmail
        async googleSignInProcessForFeed(googleSignInToken: string, saveLog: boolean = false, action: string = "", deviceDetails: any) {
            let decisionMap;
            const useUserStore = userStore()
            const useAuthenticationStore = authenticationStore()
            const googleUser = await useUserStore.getGoogleUserInfo(googleSignInToken) as GoogleSignInResponse;
            if (googleUser.email) {
                const {Result} = await useAuthenticationStore.getUserDetailsByEmailFromServer(googleUser.email, saveLog, action, deviceDetails);
                const userInfo = Result??{};
                if (userInfo.id) {
                    decisionMap = {
                        foundUser: Result
                    };
                }else{
                    decisionMap = {
                        user: googleUser
                    }
                }
            }
            return decisionMap;
        },

        ///For Feed guest continue with gmail
        async googleSignInProcessForSocialLoginSignup(
            googleSignInToken: string,
            saveLog: boolean = false,
            action: string = "",
            deviceDetails: any,
            getInfoWithAccessToken:boolean=false, /*True for custom google button*/
        ) {
            try{
                let decisionMap;
                const useUserStore = userStore()
                const useAuthenticationStore = authenticationStore()
                const googleUser = await useUserStore.getGoogleUserInfo(googleSignInToken, getInfoWithAccessToken) as GoogleSignInResponse;
                if (googleUser.email) {
                    const {Result} = await useAuthenticationStore.getUserDetailsByEmailFromServer(googleUser.email, saveLog, action, deviceDetails);
                    const userInfo = Result??{};
                    if (userInfo?.id) {
                        if(!userInfo['profilePicture']){
                            userInfo.profilePicture = googleUser.picture??'';
                        }
                        decisionMap = {
                            foundUser: Result
                        };
                    }else{
                        decisionMap = {
                            user: googleUser
                        }
                    }
                }
                return decisionMap;
            }catch (e) {
                console.log(e)
            }
        },

        ///For Feed guest -> continue with microsoft
        async microsoftSignInProcessForFeed(user: any, saveLog: boolean = false, action: string = "", deviceDetails: any) {
            let decisionMap;
            const useUserStore = userStore()
            const useAuthenticationStore = authenticationStore()
            if (user.email) {
                const {Result} = await useAuthenticationStore.getUserDetailsByEmailFromServer(user.email, saveLog, action, deviceDetails);
                const userInfo = Result??{};
                if (userInfo.id) {
                    decisionMap = {
                        foundUser: Result
                    };
                }else{
                    decisionMap = {
                        user: user
                    }
                }
            }
            return decisionMap;
        },

        async googleSignInProcess(googleSignInToken: string) {
            let goToRoute;
            const useUserStore = userStore()
            const googleUser = await useUserStore.getGoogleUserInfo(googleSignInToken) as GoogleSignInResponse;
/*            goToRoute = {
                path: AppRoutes.Signup,
                query: {
                    firstName: googleUser?.given_name??'',
                    lastName: googleUser?.family_name??'',
                    email: googleUser?.email??'',
                    picture: googleUser?.picture??'',
                    googleSignUpUser: 'true',
                },
            }*/
            if (googleUser.email) {
                if(this.userFlowType==UserFlowType.SIGN_UP_LINK_USER){
                    // Check user is already registered
                    if (this.hasParentModule) {
                        // If has parent module - Stoddert use case
                        const userInfo = await useUserStore.checkForExistingParentEmail(googleUser.email);
                        if (userInfo) {
                            //If already registered, prepare students and auth pickups
                            await this.prepareExistingDataFormLoggedInUsers(userInfo, true)
                            goToRoute = {
                                path: AppRoutes.Dashboard,
                            };
                        }
                    }
                    else {
                        // If does not have parent module - Somoswor use case
                        const userInfo = await useUserStore.getUserDetailsByEmail(googleUser.email, this.signUpCompanyId);
                        if (userInfo) {
                            // Clear local storage and add userInfo into it
                            this.clearAddedStudentsForPayment();
                            this.addIntoAddedStudentForPayment(userInfo);
                            useUserStore.setLoggedInUser(userInfo);
                            //If already registered, go to payments page
                            goToRoute = {
                                path: AppRoutes.Dashboard,
                            };
                        }
                    }
                }
                else if(this.userFlowType==UserFlowType.CUSTOM_LINK_USER){
                    // Check user is already registered
                    if (this.hasParentModule) {
                        // If has parent module - Stoddert use case
                        const userInfo = await useUserStore.checkForExistingParentEmail(googleUser.email);
                        if (userInfo) {
                            //If already registered, prepare students and auth pickups
                            await this.prepareExistingDataFormLoggedInUsers(userInfo, true)
                            goToRoute = {
                                path: AppRoutes.Dashboard,
                            };
                        }
                    }
                    else {
                        // If does not have parent module - Somoswor use case
                        const userInfo = await useUserStore.getUserDetailsByEmail(googleUser.email, this.signUpCompanyId);
                        if (userInfo) {
                            // Clear local storage and add userInfo into it
                            this.clearAddedStudentsForPayment();
                            this.addIntoAddedStudentForPayment(userInfo);
                            useUserStore.setLoggedInUser(userInfo);
                            //If already registered, go to payments page
                            goToRoute = {
                                path: AppRoutes.Dashboard,
                            };
                        }
                    }
                }
            }

            return goToRoute;
        },

        async googleSignUpProcess(googleSignInToken: string) {
            let signUpResponse;
            const useUserStore = userStore()
            const googleUser = await useUserStore.getGoogleUserInfo(googleSignInToken) as GoogleSignInResponse;
            if (googleUser.email) {
                //Return google user as response
                signUpResponse = {
                    googleUser: googleUser
                };
                if(this.userFlowType==UserFlowType.SIGN_UP_LINK_USER){
                    //Depends on parent module
                    //If has parent module
                    if(this.hasParentModule){
                        //Check user is already registered
                        const existingEmail = await useUserStore.checkForExistingParentEmail(googleUser.email);
                        if (existingEmail) {
                            //Show user exist toast
                            await this.prepareExistingDataFormLoggedInUsers(existingEmail, true)
                            signUpResponse = {
                                goToRoute: {
                                    path: AppRoutes.Dashboard,
                                }
                            };
                        }
                    }
                    else{
                        // Doesn't have parent module - Somoswor use case
                        const userDetails = await useUserStore.getUserDetailsByEmail(googleUser.email, this.signUpCompanyId);
                        if(userDetails){
                            // User is already signed up - Go to payments page
                            // Clear local storage and add userInfo into it
                            this.clearAddedStudentsForPayment();
                            this.addIntoAddedStudentForPayment(userDetails);
                            useUserStore.setLoggedInUser(userDetails);
                            this.userRegistrationType = UserRegistrationType.OLD;
                            signUpResponse = {
                                goToRoute: {
                                    path: AppRoutes.Dashboard,
                                }
                            }
                        }
                    }
                }
                else if(this.userFlowType==UserFlowType.CUSTOM_LINK_USER){
                    //Depends on parent module
                    //If has parent module
                    if(this.hasParentModule){
                        //Check user is already registered
                        const existingEmail = await useUserStore.checkForExistingParentEmail(googleUser.email);
                        if (existingEmail) {
                            //Show user exist toast
                            await this.prepareExistingDataFormLoggedInUsers(existingEmail, true)
                            signUpResponse = {
                                goToRoute: {
                                    path: AppRoutes.Dashboard,
                                }
                            };
                        }
                    }
                    else{
                        // Doesn't have parent module - Somoswor use case
                        const userDetails = await useUserStore.getUserDetailsByEmail(googleUser.email, this.signUpCompanyId);
                        if(userDetails){
                            // User is already signed up - Go to payments page
                            // Clear local storage and add userInfo into it
                            this.clearAddedStudentsForPayment();
                            this.addIntoAddedStudentForPayment(userDetails);
                            useUserStore.setLoggedInUser(userDetails);
                            signUpResponse = {
                                goToRoute: {
                                    path: AppRoutes.Dashboard,
                                }
                            }
                        }
                    }
                }
            }
            return signUpResponse;
        },
        async populateAllNecessaryData(companyId:number){
            const useUserStore = userStore();
            const useFeeStore = feeStore();
            const useClubStore = clubStore();
            const useCompanyStore = companyStore();
            const useGradeStore = gradeStore();
            const useSessionStore = sessionStore();
            await useClubStore.getAllClubsByCompanyId(companyId);
            await useCompanyStore.getCompanyDetails(companyId);
            await useGradeStore.getAllGradesByCompanyId(companyId);
            await useSessionStore.loadAllSessionByCompanyId(companyId);
            await useClubStore.getAllLocationsByCompanyId(companyId);
            await useFeeStore.loadAllFeesOfACompany(companyId)
        },
        parentEmailDecider(email:string){
            if(email.trim().length<=0){
                let tmpEmail =  'technuf_'+moment().valueOf()+'@technuf.org';
                tmpEmail = tmpEmail.replaceAll(' ','');
                return tmpEmail;
            }else{
                return email.trim();
            }
        },
        getMaskedPhoneNumberWithCountryCode(number:string){
            try{
                let phoneNumber = number.trim().replaceAll('-','');
                if(phoneNumber.length>10){
                    phoneNumber = number.substring(1);
                }
                const mask = new Mask({mask: "(###) ###-####"});
                const maskedNumber = mask.masked(phoneNumber);
                return "+1 "+maskedNumber;
            }catch (e){
                return number;
            }
        },
        userBelongsToThisCompany(user:User){
            const useCompanyStore = companyStore();
            return user.CompanyId == useCompanyStore.getCompanyId;
        },
        async processUserAccessWhoWantsToLogin(user:User){
            if(user?.id){
                const useCompanyStore = companyStore();
                const company = useCompanyStore.getCompanyDetailsLocal;
                const userBelongsToThisCompany = user.CompanyId && (user.CompanyId == company?.id);
                if(userBelongsToThisCompany){
                    return UserLoginAccessDecision.APPROVED;
                }
                else{
                    if(company?.id>0){
                        useGlobalEvent(useNotificationEvent(), <NotificationDetails>{
                            type: NotificationType.WARNING,
                            title: 'Invalid user',
                            message: 'This user does not belong to this company',
                        })
                        return UserLoginAccessDecision.DENIED;
                    }
                    else{
                        const company = await useCompanyStore.getCompanyDetails(user.CompanyId);
                        if(company?.id<1){
                            useGlobalEvent(useNotificationEvent(), <NotificationDetails>{
                                type: NotificationType.WARNING,
                                title: 'Invalid user',
                                message: 'No company found for this user',
                            })
                            return UserLoginAccessDecision.DENIED;
                        }else{
                            return UserLoginAccessDecision.CALL_LOGIN_FUNCTION_AGAIN;
                        }
                    }
                }
            }else{
                useGlobalEvent(useNotificationEvent(), <NotificationDetails>{
                    type: NotificationType.WARNING,
                    title: 'No user found',
                    message: 'No user found with this email.',
                })
                return UserLoginAccessDecision.DENIED;
            }
        }
    },
    getters: {
        getAuthorizedPickups: state => state.authorizedPickups,
        getParticipants: state => state.participants,
        getPronouns: state => state.participantPronouns,
        getAuthPickupRelations: state => state.authPickupRelations,
        getAddedStudentsForPayment: state => state.addedStudentsForPayment,
        getHasParentModule: state => state.hasParentModule,
        getHasAttendanceModule: state => state.hasAttendanceModule,
        getHasZoneModule: state => state.hasZoneModule,
        getCompanyId: state => state.signUpCompanyId,
    },
})